<template>
  <el-main class="rh-medtable">
    <h1>Formations continues</h1>
    <app-table
      tablenom="medfcontinues"
      :cols="colonnes"
      :items="fcontinues"
      :loadstatus="fcontinuesLoadStatus"
      :deletestatus="fcontinueDeleteStatus"
      :formroutes="fcontFormRoutes"
      :actions="fcontActions"
      :ismeddetail="true"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      idMed: this.$route.params.idmed, // récupère le id du médecin dans l'url
      fcontFormRoutes: {
        edit: {
          route: "med_fcontinues_edit",
          params: { idmed: "id_med", idfc: "id" },
        },
        create: {
          route: "med_fcontinues_create",
          params: { idmed: this.$route.params.idmed },
        },
      },
    };
  },
  computed: {
    fcontinuesLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.fc.fcontinuesLoadStatus;
    },
    fcontinueDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.fc.fcontinueDeleteStatus;
    },
    fcontinues() {
      // n'affiche que les fcontinues du médecin sélectionné
      return this.$store.state.fc.fcontinues.filter(
        (c) => c.id_med == this.idMed
      );
    },
    colonnes() {
      // n'affiche pas la colonne 'noms des médecins'
      return this.$store.state.fc.colonnes.filter(
        (col) => col.prop != "medecin"
      );
    },
    fcontActions() {
      return this.$store.state.fc.actions;
    },
  },
};
</script>
